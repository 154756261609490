import React, { FC } from 'react';
import styles from './AboutUsPage.module.scss';
import HighlightBlock from './components/highlightBlock/highlightBlock';

const highlightedTexts = [
  {
    id: 1,
    title: 'Mission',
    text: 'Our mission is to combine our expertise in technology and medicine to create AI solutions that elevate the healthcare system, making it more efficient and personalized.',
  },
  {
    id: 2,
    title: 'Vision',
    text: 'Our vision is to innovate the future of healthcare, creating solutions that enhance accessibility and patient-centric care. Join us as we pioneer the evolution of global health systems.',
  },
];

const AboutUsPage: FC = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.titleWrapper}>
        <h2 className={styles.title}>About us</h2>
      </div>
      <div className={styles.teamWrapper}>
        <div className={styles.teamContainer}>
          <div className={styles.teamTextWrapper}>
            <h3 className={styles.teamTitle}>Our Team</h3>
            <p className={styles.teamText}>
              We are a team of AI engineers, computer scientists, and data scientists, all educated
              at premier global universities, specializing in natural language processing. Our
              unique edge is the blend of tech and medical expertise we offer, strengthened by
              medical advisors who guide our product development, grounding it in real-world
              healthcare needs.
            </p>
            <p className={styles.teamText}>
              Our app, BetterMed v2.0, is a product of Vivo Health. Leveraging advanced natural
              language processing systems, BetterMed serves as a virtual aide, conversing with
              patients, understanding their profiles, and providing relevant healthcare diagnosis
              and suggestions. This AI-powered app, with its intelligent triage and classification
              system, prioritizes urgent cases, making healthcare more accessible and efficient. You
              can find us at vivo.health.
            </p>
          </div>
        </div>
      </div>
      <div className={styles.highlightBlocksWrapper}>
        {highlightedTexts.map(({ id, title, text }) => (
          <HighlightBlock key={`key_${id}`} title={title} text={text} />
        ))}
      </div>
      <div className={styles.universityWrappers}>
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};

export default React.memo(AboutUsPage);
