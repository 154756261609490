import React, { FC } from 'react';
import ContactUsForm from 'components/shared/forms/contact-us-form/ContactUsForm';
import classes from './ContacUs.module.scss';

const ContactUs: FC = () => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.titleWrapper}>
        <h2 className={classes.title}>Contact us</h2>
        <p className={classes.text}>
          Please use the form below to get in touch with us. We&apos;ll get back to you as soon as
          possible.
        </p>
      </div>
      <ContactUsForm />
    </div>
  );
};

export default ContactUs;
