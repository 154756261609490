import React, { FC } from 'react';
import paperClip from 'assets/icons/paper-clip.svg';

type InputProps = {
  multiple: boolean;
  onFileUpload: (file: File[]) => void;
};

export const InputFile: FC<InputProps> = ({ onFileUpload, multiple = false }) => {
  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const fileArray = Array.from(files);
      onFileUpload(fileArray);
    }
  };

  return (
    <label className={'fileInput'} htmlFor="file-input">
      <img src={paperClip} alt="attach icon" />
      <input
        type="file"
        id="file-input"
        accept=".doc,.docx,application/msword,.pdf,.png,.jpg,.jpeg"
        hidden
        multiple={multiple}
        onChange={handleFileInputChange}
      />
    </label>
  );
};

export default InputFile;
