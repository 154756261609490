import React, { FC, useEffect, useState } from 'react';
import styles from './LandingHome.module.scss';
import { Outlet, useLocation } from 'react-router-dom';

import DefaultSecondaryImage from 'assets/images/landing-medicsister.png';
import ContactUsSecondaryImage from 'assets/images/landing-medic.png';
import { ROUTE_CONTACT_US } from 'app-constants';

export const LandingHome: FC = () => {
  const location = useLocation();
  const [secondaryImage, setSecondaryImage] = useState<string>();

  useEffect(() => {
    switch (location.pathname) {
      case `/${ROUTE_CONTACT_US}`:
        setSecondaryImage(ContactUsSecondaryImage);
        break;
      default:
        setSecondaryImage(DefaultSecondaryImage);
        break;
    }
  }, [location]);

  return (
    <div className={styles.LandingPageWrapper}>
      <div className={styles.LeftPart}>
        {<img className={styles.DynamicImage} src={secondaryImage} alt={'dynamic-image'} />}
      </div>
      <div className={styles.RightPart}>
        <Outlet />
      </div>
    </div>
  );
};

export default LandingHome;
