import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Button from 'components/shared/button/Button';
import { MODAL_TYPES, useGlobalModalContext } from 'contexts/GlobalModalContext';
import { GeneralActions, GeneralSelectors, useAppDispatch } from 'store';
import AuthHelpers from 'utils/AuthHelpers';
import { FEATURES } from './features.constant';
import classes from './LandingHomePage.module.scss';

const LandingHomePage: FC = () => {
  const { showModal } = useGlobalModalContext();

  const isLoggedIn = useSelector(GeneralSelectors.selectIsLoggedIn);

  const dispatch = useAppDispatch();

  const handleDemoRequest = () => {
    showModal(MODAL_TYPES.DEMO_REQUEST_MODAL);
  };

  useEffect(() => {
    if (!isLoggedIn && AuthHelpers.getAccessToken()) {
      dispatch(GeneralActions.setIsLoggedIn());
    }
  }, [isLoggedIn]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.TopSection}>
        <h2 className={classes.heading}>Empowering Healthcare with BetterMed Medical Assistant </h2>
        <p className={classes.text}>Accelerating Healthcare with AI-driven Systems</p>
        <div className={classes.buttonWrapper}>
          <Button text="Demo Request" onClick={handleDemoRequest} />
        </div>
      </div>
      <div className={classes.featuresWrapper}>
        {FEATURES.map((feature) => (
          <div key={feature.id}>
            <img src={feature.icon} alt={`${feature.name} icon`} />
            <p className={classes.featureName}>{feature.name}</p>
            <p className={classes.featureCaption}>{feature.caption}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LandingHomePage;
