import React, { FC } from 'react';
import ModalWindow from '../modal-window/ModalWindow';
import { useGlobalModalContext } from 'contexts/GlobalModalContext';
import ButtonOutlined from '../buttonOutlined/ButtonOutlined';
import Button from '../button/Button';
import classes from 'styles/modules/Modal.module.scss';

type Props = {
  heading: string;
  caption?: string;
  onClose: () => void;
};

export const ConfirmationModal: FC<Props> = ({ heading, caption, onClose }) => {
  const { hideModal } = useGlobalModalContext();

  return (
    <ModalWindow visible={true} setVisible={hideModal}>
      <div className={classes.modalWrapper}>
        <h2 className={classes.modalHeading}>{heading}</h2>
        {caption && <p className={classes.text}>{caption}</p>}
        <div className={classes.buttonsWrapper}>
          <div className={classes.buttonItemWrapper}>
            <ButtonOutlined text="Cancel" onClick={hideModal} />
          </div>
          <div className={classes.buttonItemWrapper}>
            <Button text="Yes" onClick={onClose} />
          </div>
        </div>
      </div>
    </ModalWindow>
  );
};

export default ConfirmationModal;
