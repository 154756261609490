import React from 'react';
import { ButtonInterface } from 'interfaces';
import classes from './Button.module.scss';

const Button: React.FC<ButtonInterface> = ({ text, onClick, type = 'button' }) => {
  return (
    <button type={type} className={classes.button} onClick={onClick}>
      {text}
    </button>
  );
};

export default Button;
