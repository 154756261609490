import React, { forwardRef } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import Select from 'react-select';

interface Props {
  name: string;
  options: { value: string; label: string }[];
  currentValue: string;
  setValue: UseFormSetValue<any>;
  placeholder: string;
  label?: string;
  inputClassList?: string[];
  labelClassList?: string[];
}

const InputSelect = forwardRef<any, Props>(
  (
    {
      name,
      options,
      currentValue,
      setValue,
      placeholder,
      label = '',
      inputClassList = [],
      labelClassList = [],
    },
    ref
  ) => {
    return (
      <div className={'form-group'}>
        {label && <label className={`label ${labelClassList?.join(' ')}`}>{label}</label>}
        <Select
          autoFocus={false}
          ref={ref}
          name={name}
          value={options.find((c) => c.value === currentValue)}
          onChange={(val) => {
            if (val) {
              setValue('gender', val.value);
            }
          }}
          classNamePrefix={'customSelect'}
          className={`${inputClassList?.join(' ')}`}
          placeholder={placeholder}
          options={options}
          isSearchable={false}
        />
      </div>
    );
  }
);

InputSelect.displayName = 'InputSelect';

export default InputSelect;
