import { NavLinkInterface } from 'interfaces';

export const ROUTE_ROOT = '';
export const ROUTE_USER_ACCOUNT = 'user-account';
export const ROUTE_CONTACT_US = 'contact-us';

export const ROUTE_SIGN_IN = 'sign-in';
export const ROUTE_SIGN_UP = 'sign-up';

export const ROUTE_ABOUT_US = 'about-us';
export const ROUTE_PRODUCT = 'product';

export const NAV_LINKS: NavLinkInterface[] = [
  { id: 1, link: `/`, label: 'Home' },
  { id: 2, link: `/about-us`, label: 'About us' },
  { id: 3, link: `/product`, label: 'Product' },
  { id: 4, link: `/contact-us`, label: 'Contact Us' },
];
