import React, { FC } from 'react';
import { useGlobalModalContext } from 'contexts/GlobalModalContext';
import checkmark from 'assets/icons/checkMark.svg';
import ModalWindow from '../shared/modal-window/ModalWindow';
import classes from 'styles/modules/Modal.module.scss';

export const ReportLoadingModal: FC = () => {
  const { hideModal } = useGlobalModalContext();

  return (
    <ModalWindow visible={true} setVisible={hideModal}>
      <div className={classes.modalWrapper}>
        <img src={checkmark} alt="checkmark icon" />
        <h2 className={classes.modalHeading}>Please wait...</h2>
        <p className={classes.text}>Thank you for your time.</p>
      </div>
    </ModalWindow>
  );
};

export default ReportLoadingModal;
