import React, { FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { ROUTE_USER_ACCOUNT } from 'app-constants';
import { GeneralSelectors } from 'store';
import { useGlobalModalContext } from 'contexts/GlobalModalContext';
import { UserInfoActions } from 'store/slices/user';
import InputSelect from 'components/shared/form-controls/inputSelect/inputSelect';
import Input from '../../form-controls/input/Input';
import Button from '../../button/Button';
import style from 'styles/modules/Auth.module.scss';

interface Form {
  name: string;
  age: number;
  gender: string;
}

const options = [
  { value: 'Male', label: 'Male' },
  { value: 'Female', label: 'Female' },
  { value: 'Other', label: 'Other' },
];

export const AdditionalInformationForm: FC = () => {
  const {
    control,
    getValues,
    setValue,
    register,
    reset,
    formState: { errors },
  } = useForm<Form>();
  const { hideModal } = useGlobalModalContext();

  const isError = useSelector(GeneralSelectors.selectIsError);
  const isDemoRequestAccepted = useSelector(GeneralSelectors.selectIsDemoRequestAccepted);

  const dispatch: ThunkDispatch<unknown, unknown, AnyAction> = useDispatch();

  const navigate = useNavigate();

  const handleClick = () => {
    if (isDemoRequestAccepted && getValues('name') && getValues('age') && getValues('gender')) {
      dispatch(UserInfoActions.setUserInfo(getValues()));
      reset();
      hideModal();
      navigate(`/${ROUTE_USER_ACCOUNT}`);
    }
  };

  useEffect(() => {
    if (isError) {
      console.error('Got error while trying to set additional info');
    }
  }, [isError]);

  return (
    <form className={`form ${style.form}`}>
      <div className={style.inputBlock}>
        <div className={style.inputWrapper}>
          <p className={style.inputLabel}>Name</p>
          <Input
            label="name"
            register={register}
            type="text"
            placeholder="Enter your name"
            errors={errors}
          />
        </div>
        <div className={style.inputWrapper}>
          <p className={style.inputLabel}>Age</p>
          <Input
            label="age"
            register={register}
            type="number"
            placeholder="Enter your age"
            errors={errors}
          />
        </div>
        <div className={style.inputWrapper}>
          <p className={style.inputLabel}>Biological sex</p>
          <Controller
            control={control}
            name={'gender'}
            rules={{ required: true }}
            render={({ field: { value, name, ref } }) => (
              <InputSelect
                ref={ref}
                name={name}
                options={options}
                currentValue={value}
                setValue={setValue}
                placeholder={'Select your biological sex'}
              />
            )}
          />
        </div>
      </div>
      <div className={style.buttonWrapper}>
        <Button type={'submit'} text={'Submit'} onClick={handleClick} />
      </div>
      <input type={'submit'} hidden />
    </form>
  );
};

export default AdditionalInformationForm;
