import React, { FC } from 'react';
import { useGlobalModalContext } from 'contexts/GlobalModalContext';
import ModalWindow from '../shared/modal-window/ModalWindow';
import DemoRequestForm from '../shared/forms/demo-request-form/DemoRequestForm';
import classes from 'styles/modules/Modal.module.scss';

const DemoRequestModal: FC = () => {
  const { hideModal } = useGlobalModalContext();

  return (
    <ModalWindow visible={true} setVisible={hideModal}>
      <div className={classes.modalWrapper}>
        <div>
          <h2 className={classes.modalHeading}>Please enter your Password</h2>
        </div>
        <DemoRequestForm />
      </div>
    </ModalWindow>
  );
};

export default DemoRequestModal;
