import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGlobalModalContext } from 'contexts/GlobalModalContext';
import { GeneralActions, useAppDispatch, UserInfoActions } from 'store';
import ModalWindow from '../shared/modal-window/ModalWindow';
import Button from '../shared/button/Button';
import classes from 'styles/modules/Report.module.scss';
import { ChatReport } from '@interfaces/chat.interface';
import FileMessage from '../../modules/user-account/pages/home/components/fileMessage/fileMessage';
import { getFiles } from 'api/chatApi';
import AuthHelpers from '../../utils/AuthHelpers';
import { ChatFile } from '@interfaces/file.interface';

export const ReportModal: FC = () => {
  const { hideModal, store } = useGlobalModalContext();
  const { modalProps } = (store || {}) as { modalProps: ChatReport };
  const [files, setFiles] = useState<any>([]);

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const closeModal = () => {
    hideModal();
    dispatch(GeneralActions.resetState());
    dispatch(UserInfoActions.resetState());
    navigate('/');
  };

  const getAttachments = async () => {
    const res = await getFiles(AuthHelpers.getWSSessionId());
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    res?.forEach((file: ChatFile) => {
      const newFile = () => {
        if (/\.(png|jpe?g)$/i.test(file.file_extension)) {
          return {
            name: file.file_name.split('.')[0],
            imageSrc: `${process.env.REACT_APP_API_URL}${decodeURI(file.url)}`,
            fileLink: `${process.env.REACT_APP_API_URL}${decodeURI(file.url)}`,
            size: file.size_in_byte,
          };
        } else {
          return {
            name: file.file_name.split('.')[0],
            extension: file.file_extension,
            fileLink: `${process.env.REACT_APP_API_URL}${decodeURI(file.url)}`,
            size: file.size_in_byte,
          };
        }
      };

      setFiles((prev: any) => [...prev, newFile()]);
    });
  };

  useEffect(() => {
    getAttachments();
  }, []);

  return (
    <ModalWindow visible={true} setVisible={hideModal}>
      <div className={classes.reportWrapper}>
        <h3 className={classes.reportHeading}>Report</h3>
        <div className={classes.nameWrapper}>
          <p className={classes.name}>{modalProps.patient.name}</p>
          {modalProps.is_urgent ? (
            <div className={`${classes.type} ${classes.urgent}`}>Urgent</div>
          ) : (
            <div className={`${classes.type} ${classes.nonurgent}`}>Not urgent</div>
          )}
        </div>
        <div className={classes.reportList}>
          <div className={classes.reportItemWrapper}>
            <p className={classes.reportItemValue}>
              <span className={classes.reportItemTitle}>Biological sex:</span>
              {modalProps.patient.gender}
            </p>
          </div>
          <div className={classes.reportItemWrapper}>
            <p className={classes.reportItemValue}>
              <span className={classes.reportItemTitle}>Age:</span>
              {modalProps.patient.age}
            </p>
          </div>
          <div className={classes.reportItemWrapper}>
            <p className={classes.reportItemValue}>
              <span className={classes.reportItemTitle}>Field of medicine:</span>
              {modalProps.specialist}
            </p>
          </div>
          {modalProps.summary.map((reportItem, index) => (
            <div key={`key_${index}`} className={classes.reportItemWrapper}>
              <p className={classes.reportItemValue}>
                <span className={classes.reportItemTitle}>{reportItem.title}:</span>
                {reportItem.content}
              </p>
            </div>
          ))}
          <div className={classes.reportItemWrapper}>
            <p className={classes.reportItemValue}>
              <span className={classes.reportItemTitle}>Attachment:</span>
              <div className={classes.filesList}>
                {files.map((attachment, index) => (
                  <div key={`key_${index}`} className={classes.fileWrapper}>
                    <FileMessage data={attachment} />
                  </div>
                ))}
              </div>
            </p>
          </div>
        </div>
        <div className={classes.buttonItemWrapper}>
          <Button text="Close" onClick={closeModal} />
        </div>
      </div>
    </ModalWindow>
  );
};

export default ReportModal;
