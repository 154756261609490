import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';
import styles from './UserAccountHome.module.scss';

export const UserAccountHome: FC = () => {
  return (
    <div className={styles.UserAccountPageWrapper}>
      <Outlet />
    </div>
  );
};

export default UserAccountHome;
