import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import AuthHelpers from 'utils/AuthHelpers';

interface UserInfoPayload {
  name: string;
  age: number;
  gender: string;
}

interface UserInfoState {
  user: {
    name: string;
    age: number;
    gender: string;
  };
}

const initialState: UserInfoState = {
  user: {
    name: '',
    age: 0,
    gender: '',
  },
};

const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {
    setUserInfo(state, action: PayloadAction<UserInfoPayload>) {
      state.user.name = action.payload.name;
      state.user.age = action.payload.age;
      state.user.gender = action.payload.gender;
      //TODO refactor after demo
      AuthHelpers.storeUserInfo(state.user);
    },
    resetState(state) {
      state.user.name = '';
      state.user.age = 0;
      state.user.gender = '';
    },
  },
  extraReducers: {},
});

export const UserInfoActions = { ...userInfoSlice.actions };
export const UserInfoEffects = {};
export const userInfoReducer = userInfoSlice.reducer;
