import React, { FC } from 'react';
import { useGlobalModalContext } from 'contexts/GlobalModalContext';
import ConfirmationModal from 'components/shared/confirmationModal/ConfirmationModal';

interface Props {
  heading: string;
  handleDelete: () => void;
}

export const DeleteAttachmentConfirmationModal: FC<Props> = ({ heading, handleDelete }) => {
  const { hideModal } = useGlobalModalContext();

  const closeChat = () => {
    handleDelete();
    hideModal();
  };

  return <ConfirmationModal heading={heading} onClose={closeChat} />;
};

export default DeleteAttachmentConfirmationModal;
