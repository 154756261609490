import React, { FC, ReactElement, useEffect, useRef, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import doctor from 'assets/icons/doctor.svg';
import user from 'assets/icons/user.svg';
import more from 'assets/icons/more.svg';
import send from 'assets/icons/send.svg';
import { ChatMessage } from 'interfaces/chat.interface';
import { GeneralSelectors, UserInfoSelectors } from 'store';
import Button from 'components/shared/button/Button';
import ButtonOutlined from 'components/shared/buttonOutlined/ButtonOutlined';
import { MODAL_TYPES, useGlobalModalContext } from 'contexts/GlobalModalContext';
import styles from './UserAccountHomePage.module.scss';
import AuthHelpers from 'utils/AuthHelpers';
import InputFile from 'components/shared/form-controls/inputFile/InputFile';
import { deleteFile } from 'api/chatApi';
import { v4 as uuidv4 } from 'uuid';
import FileMessage from './components/fileMessage/fileMessage';

const UserAccountHomePage: FC = () => {
  const [messagesReceived, setMessagesReceived] = useState<ChatMessage[]>([]);
  const [message, setMessage] = useState<string>('');
  const [webSocket, setWebSocket] = useState<WebSocket | undefined>();
  const [chatHeight, setChatHeight] = useState<number>(450);
  const [isChatEnded, setIsChatEnded] = useState<boolean>(false);

  const messagesEndRef = useRef<HTMLDivElement>(null);

  const isDemoRequestAccepted = useSelector(GeneralSelectors.selectIsDemoRequestAccepted);
  const { name, age, gender } =
    process.env.NODE_ENV !== 'development'
      ? useSelector(UserInfoSelectors.selectUser)
      : { name: 'Test', age: 20, gender: 'Male' };

  const { showModal, hideModal } = useGlobalModalContext();

  const navigate = useNavigate();

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value);
  };

  const getMessageTime = () => {
    return new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  const sendMessage = (forceMessage = '') => {
    if (webSocket?.readyState === 3) {
      navigate('/');
      return;
    }

    if (forceMessage.length) {
      webSocket?.send(forceMessage);
      return;
    }

    if (!message.length) {
      return;
    }

    webSocket?.send(message);
    setMessagesReceived([
      ...messagesReceived,
      { id: uuidv4(), user: true, type: 'text', message, createdTime: getMessageTime() },
    ]);
    setMessage('');
  };

  const handleDelete = async ({ fileName, id }) => {
    await deleteFile({ session_id: AuthHelpers.getWSSessionId(), file_name: fileName });
    const filteredMessages = messagesReceived.filter((m: ChatMessage) => m.id !== id);
    setMessagesReceived(filteredMessages);
  };

  const onDelete = ({ fileName, id }) => {
    showModal(MODAL_TYPES.DELETE_ATTACHMENT_CONFIRMATION_MODAL, {
      heading: 'Are you sure you want to delete the attachment?',
      handleDelete: () => handleDelete({ fileName, id }),
    });
  };

  const getMessage = (data: ChatMessage): ReactElement => {
    switch (data.type) {
      case 'text':
        return (
          <div className={`${styles.Message} `}>
            {!data.user && <img src={doctor} alt="doctor profile icon" />}
            <div className={styles.MessageContainer}>
              <div className={`${styles.MessageWrapper} ${styles.Bot}`}>{data.message}</div>
              <div className={styles.MessageTime}>{data.createdTime}</div>
            </div>
          </div>
        );
      case 'document':
        return (
          <FileMessage
            data={data}
            onDelete={() => onDelete({ fileName: `${data.name}.${data.extension}`, id: data.id })}
          />
        );
      case 'image':
        return (
          <FileMessage
            data={data}
            onClick={() => showModal(MODAL_TYPES.PREVIEW_IMAGE_MODAL, { imageSrc: data.imageSrc })}
            onDelete={() => onDelete({ fileName: `${data.name}.${data.extension}`, id: data.id })}
          />
        );
      default:
        return <div />;
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.code === 'Enter') {
      if (message.trim().length) {
        sendMessage();
        e.preventDefault();
      }
    }
  };

  const onTextAreaHeightChange = (height: number) => {
    setChatHeight(height === 20 ? 450 : 450 + 20 - height);
  };

  const handleYesAnswerClick = () => {
    showModal(MODAL_TYPES.REPORT_LOADING_MODAL);
    sendMessage('yes');
  };

  useEffect(() => {
    if (!isDemoRequestAccepted) {
      navigate('/');
    } else {
      const socket = new WebSocket(
        `${process.env.REACT_APP_WS_URL}/demo/chat?name=${name}&age=${age}&gender=${gender}`
      );

      socket.onopen = () => {
        console.log('Connected');
      };

      socket.onclose = () => {
        console.log('Closed');
      };

      socket.onmessage = (data: MessageEvent) => {
        if (data.data === 'yes/no?') {
          setIsChatEnded(true);
          return;
        }

        if (data.data.includes('sessionId')) {
          AuthHelpers.storeWSSessionId(data.data.split(':')[1]);
          return;
        }

        try {
          const report = JSON.parse(data.data);
          hideModal();
          showModal(MODAL_TYPES.REPORT_MODAL, report);
        } catch {
          setMessagesReceived((state: ChatMessage[]) => [
            ...state,
            {
              id: uuidv4(),
              user: false,
              type: 'text',
              message: data.data,
              createdTime: getMessageTime(),
            },
          ]);
        }
      };

      setWebSocket(socket);

      return () => {
        socket.close();
        console.log('Closed');
      };
    }
  }, []);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messagesReceived]);

  const onFileUpload = (files: File[]) => {
    try {
      const newMessages = files.map((file) => {
        return {
          id: uuidv4(),
          user: true,
          type: file.type.includes('image') ? 'image' : 'document',
          name: file.name.split('.')[0] as string,
          size: file.size,
          extension: file.name.split('.').pop() as string,
          imageSrc: file.type.includes('image') ? URL.createObjectURL(file) : null,
          fileLink: file.type.includes('image') ? null : URL.createObjectURL(file),
          createdTime: getMessageTime(),
          file,
        };
      });

      const newMessageReceived = [...messagesReceived, ...newMessages];
      setMessagesReceived(newMessageReceived);
    } catch (error) {
      console.log(error);
    }
  };

  const renderUserInput = () => {
    switch (isChatEnded) {
      case true:
        return (
          <div className={styles.endChatWrapper}>
            <div className={styles.buttonWrapper}>
              <Button text={'Yes'} onClick={handleYesAnswerClick} />
            </div>
            <div className={styles.buttonWrapper}>
              <ButtonOutlined
                text={'No'}
                onClick={() => showModal(MODAL_TYPES.CLOSE_CHAT_CONFIRMATION_MODAL)}
              />
            </div>
          </div>
        );
      case false:
        return (
          <div className={styles.textAreaWrapper}>
            <TextareaAutosize
              placeholder={'Type a message...'}
              maxRows={3}
              onHeightChange={onTextAreaHeightChange}
              value={message}
              className={styles.textArea}
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
            />
            <div className={styles.attachButton}>
              <InputFile multiple={true} onFileUpload={onFileUpload} />
            </div>
            <button className={styles.sendButton} onClick={() => sendMessage()}>
              <img src={send} alt="send icon" />
            </button>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className={styles.UserAccountHomePageWrapper}>
      <div className={styles.UserProfileInfo}>
        <div className={styles.UserProfileContent}>
          <img src={user} alt="user profile icon" />
          <p className={styles.userName}>{name}</p>
          <div className={styles.userInfoList}>
            <div className={styles.userInfoWrapper}>
              <p className={styles.userInfoCaption}>Age:</p>
              <p className={styles.userInfoValue}>{age}</p>
            </div>
            <div className={styles.userInfoWrapper}>
              <p className={styles.userInfoCaption}>Biological sex:</p>
              <p className={styles.userInfoValue}>{gender}</p>
            </div>
          </div>
          <button className={styles.moreButton}>
            <img src={more} alt="more" />
          </button>
        </div>
      </div>
      <div className={styles.UserAccountChat}>
        <div className={styles.UserAccountChatHeader}>
          <div className={styles.doctorStatusWrapper}>
            <img src={doctor} alt="doctor profile icon" />
            <div>
              <p className={styles.doctorName}>BetterMed</p>
              <p className={styles.doctorStatus}>Active</p>
            </div>
          </div>
        </div>
        <div className={styles.UserAccountChatDiscussion}>
          <div className={styles.DiscussionContainer} style={{ height: chatHeight }}>
            {messagesReceived.map((msg) => (
              <div
                className={`${styles.MessageItem} ${
                  msg.user ? styles.UserMessage : styles.BotMessage
                }`}
                key={msg.id}
              >
                {getMessage(msg)}
              </div>
            ))}
            <div ref={messagesEndRef} />
          </div>
        </div>
        <div className={styles.UserAccountChatInput}>{renderUserInput()}</div>
      </div>
    </div>
  );
};

export default React.memo(UserAccountHomePage);
