import React, { FC } from 'react';
import { Path, UseFormRegister, ValidationRule } from 'react-hook-form';
import { FieldErrors } from 'react-hook-form/dist/types/errors';

type InputType = 'text' | 'password' | 'number' | 'date' | 'file';

type InputProps = {
  label: Path<any>;
  register: UseFormRegister<any>;
  type: InputType;
  placeholder?: string;
  errors?: FieldErrors<any>;
  required?: boolean;
  inputLabel?: string;
  value?: any;
  classList?: string[];
  inputClassList?: string[];
  labelClassList?: string[];
  pattern?: ValidationRule<RegExp> | undefined;
};

export const Input: FC<InputProps> = ({
  label,
  inputLabel,
  register,
  required = false,
  type,
  placeholder = '',
  errors = '',
  pattern = undefined,
  classList = [],
  inputClassList = [],
  labelClassList = [],
}) => {
  return (
    <div className={`form-group ${type === 'number' ? 'number' : ''}`}>
      {inputLabel && <label className={`label ${labelClassList?.join(' ')}`}>{inputLabel}</label>}
      <div className={`input-wrapper ${classList.join(' ')}`}>
        <input
          className={`input ${inputClassList.join(' ')} ${
            errors && errors[label] && 'field-invalid'
          }`}
          type={type}
          placeholder={placeholder}
          {...register(label, { required, pattern })}
        />
      </div>
      {errors && <span className={'validation-error'}>{errors[label]?.message}</span>}
    </div>
  );
};

export default Input;
