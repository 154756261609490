import React, { FC } from 'react';
import classes from './SignIn.module.scss';
import SignInForm from 'components/shared/forms/sign-in-form/SignInForm';

export const SignIn: FC = () => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.titleWrapper}>
        <h2 className={classes.title}>Sign In</h2>
        <p className={classes.text}>Please login with your BetterMed account password</p>
      </div>
      <div>
        <SignInForm />
      </div>
    </div>
  );
};

export default SignIn;
