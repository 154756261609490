import React, { FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import Button from '../../button/Button';
import Input from '../../../shared/form-controls/input/Input';
import TextArea from '../../../shared/form-controls/textarea/textArea';
import style from 'styles/modules/Auth.module.scss';

interface Form {
  name: string;
  email: string;
  phone: string;
  subject: string;
  message: string;
}

export const ContactUsForm: FC = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Form>();

  const onSubmit: SubmitHandler<Form> = ({ name, email, phone, subject, message }) => {
    console.log({ name, email, phone, subject, message });
    reset();
  };

  return (
    <form className={`form ${style.form}`} onSubmit={handleSubmit(onSubmit)}>
      <div className={style.inputBlock}>
        <Input
          inputLabel="Name"
          labelClassList={['labelWhite']}
          inputClassList={['inputTransparent']}
          label="name"
          register={register}
          type="text"
          placeholder="Enter your name"
          errors={errors}
        />
      </div>
      <div className={style.inputBlock}>
        <Input
          inputLabel="Email address"
          labelClassList={['labelWhite']}
          inputClassList={['inputTransparent']}
          label="email"
          register={register}
          type="text"
          placeholder="Enter your email address"
          errors={errors}
        />
      </div>
      <div className={style.inputBlock}>
        <Input
          inputLabel="Phone number"
          labelClassList={['labelWhite']}
          inputClassList={['inputTransparent']}
          label="number"
          register={register}
          type="text"
          placeholder="Enter your phone number"
          errors={errors}
        />
      </div>
      <div className={style.inputBlock}>
        <Input
          inputLabel="Subject"
          labelClassList={['labelWhite']}
          inputClassList={['inputTransparent']}
          label="subject"
          register={register}
          type="text"
          placeholder="Enter a subject of your message"
          errors={errors}
        />
      </div>
      <div className={style.inputBlock}>
        <TextArea
          labelClassList={['labelWhite']}
          inputClassList={['inputTransparent']}
          label={'Message'}
          placeholder={'Enter your message'}
          inputLabel={'Message'}
          register={register}
          errors={errors}
        />
      </div>
      <div className={style.buttonWrapper}>
        <Button type={'submit'} text={'Submit'} onClick={() => handleSubmit(onSubmit)} />
      </div>
      <input type={'submit'} hidden />
    </form>
  );
};

export default ContactUsForm;
