import React, { FC } from 'react';
import { Path, UseFormRegister } from 'react-hook-form';
import { FieldErrors } from 'react-hook-form/dist/types/errors';

type InputProps = {
  label: Path<any>;
  register: UseFormRegister<any>;
  placeholder?: string;
  errors?: FieldErrors<any>;
  required?: boolean;
  inputLabel?: string;
  value?: any;
  classList?: string[];
  inputClassList?: string[];
  labelClassList?: string[];
};

export const TextArea: FC<InputProps> = ({
  label,
  inputLabel,
  register,
  required = false,
  placeholder = '',
  errors = '',
  classList = [],
  inputClassList = [],
  labelClassList = [],
}) => {
  return (
    <div className={`form-group`}>
      {inputLabel && <label className={`label ${labelClassList?.join(' ')}`}>{inputLabel}</label>}
      <div className={`input-wrapper ${classList.join(' ')}`}>
        <textarea
          className={`input textArea ${inputClassList.join(' ')} ${
            errors && errors[label] && 'field-invalid'
          }`}
          placeholder={placeholder}
          {...register(label, { required })}
        />
      </div>
      {errors && <span className={'validation-error'}>{errors[label]?.message}</span>}
    </div>
  );
};

export default TextArea;
