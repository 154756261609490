import { configureStore } from '@reduxjs/toolkit';

import { generalReducer as general } from './slices/general';
import { userInfoReducer as userInfo } from './slices/user';

const store = configureStore({
  reducer: { general, userInfo },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;

export default store;
