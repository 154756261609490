import React, { FC } from 'react';
import { Link, NavLink } from 'react-router-dom';
import logo from 'assets/icons/logo.svg';
import { NAV_LINKS, ROUTE_ROOT } from 'app-constants';
import { NavLinkInterface } from 'interfaces';
import { MODAL_TYPES, useGlobalModalContext } from 'contexts/GlobalModalContext';
import Button from '../../shared/button/Button';
import classes from './Header.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { GeneralEffects, GeneralSelectors } from 'store';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';

export const Header: FC = () => {
  const isLoggedIn = useSelector(GeneralSelectors.selectIsLoggedIn);
  const dispatch: ThunkDispatch<unknown, unknown, AnyAction> = useDispatch();
  const { showModal } = useGlobalModalContext();

  const handleDemoRequest = () => {
    showModal(MODAL_TYPES.DEMO_REQUEST_MODAL);
  };

  const logout = () => {
    dispatch(GeneralEffects.logout());
  };

  return (
    <header className={classes.header}>
      <section className="section">
        <div className={classes.wrapper}>
          <Link to={ROUTE_ROOT} className={classes.logoWrapper}>
            <img src={logo} alt="logo" />
            <h1 className={classes.logo}>BetterMed</h1>
          </Link>
          <div className={classes.navBlock}>
            <nav className={classes.navWrapper}>
              <ul>
                {NAV_LINKS.map((link: NavLinkInterface) => {
                  return (
                    <li key={link.id}>
                      <NavLink
                        className={({ isActive }) => {
                          return isActive ? classes.activeLink : classes.link;
                        }}
                        to={link.link}
                      >
                        {link.label}
                      </NavLink>
                    </li>
                  );
                })}
              </ul>
            </nav>
            <div className={classes.buttonWrapper}>
              <Button text={'Demo'} onClick={handleDemoRequest} />
            </div>
            {isLoggedIn && (
              <button
                type="button"
                title="Logout"
                className={classes.LogOutButton}
                onClick={logout}
              >
                Log Out
              </button>
            )}
          </div>
        </div>
      </section>
    </header>
  );
};
