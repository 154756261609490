import { api } from './axios.config';

export const uploadFile = (formData) => {
  return api.post('/demo/files', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const deleteFile = (formData) => {
  return api.delete(`/demo/file?session_id=${formData.session_id}&file_name=${formData.file_name}`);
};

export const getFiles = (sessionId) => {
  return api.get(`/demo/files?session_id=${sessionId}`);
};
