import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { GeneralActions, useAppDispatch, UserInfoActions } from 'store';
import { useGlobalModalContext } from 'contexts/GlobalModalContext';
import ConfirmationModal from 'components/shared/confirmationModal/ConfirmationModal';

export const CloseChatConfirmationModal: FC = () => {
  const { hideModal } = useGlobalModalContext();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const closeChat = () => {
    hideModal();
    dispatch(UserInfoActions.resetState());
    dispatch(GeneralActions.resetState());
    navigate('/');
  };

  return (
    <ConfirmationModal
      heading={'Are you sure?'}
      caption={
        'Your answers won’t be saved. Are you sure you don’t want to redirect to one of our doctors?'
      }
      onClose={closeChat}
    />
  );
};

export default CloseChatConfirmationModal;
