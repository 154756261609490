import React, { FC, useEffect, useState } from 'react';
import styles from '../../UserAccountHomePage.module.scss';
import { ReactComponent as Trash } from 'assets/icons/trash.svg';
import { ReactComponent as Loader } from 'assets/icons/loader.svg';
import { Link } from 'react-router-dom';
import AuthHelpers from 'utils/AuthHelpers';
import { uploadFile } from 'api/chatApi';
import { getFileSize } from 'utils';

type Props = {
  onClick?: () => void;
  data: any;
  onDelete?: () => void;
};

const FileMessage: FC<Props> = ({ onClick, data, onDelete }) => {
  const [fileUploading, setFileUploading] = useState<boolean>(false);

  const deleteFile = (event) => {
    event.preventDefault();
    event.stopPropagation();
    onDelete?.();
  };

  const fileMessageContent = () => {
    return (
      <>
        <div className={`${styles.Message}`}>
          <div className={styles.MessageContainer}>
            <div className={`${styles.MessageWrapper} ${styles.Bot} ${styles.fileWrapper}`}>
              <div className={styles.fileExtension}>
                {data.imageSrc ? (
                  <img src={data.imageSrc} alt="user attachment preview" />
                ) : (
                  <>
                    {fileUploading ? (
                      <div className={styles.loader}>
                        <Loader />
                      </div>
                    ) : (
                      <> {data.extension}</>
                    )}
                  </>
                )}
              </div>
              <div className={styles.fileCaptionWrapper}>
                <div className={styles.fileName}>{data.name}</div>
                {data.size && <div className={styles.fileSize}>{getFileSize(data.size)}</div>}
              </div>
            </div>
            <div className={styles.MessageTime}>{data.createdTime && data.createdTime}</div>
          </div>
        </div>
        {onDelete && (
          <button type="button" className={styles.deleteButton} onClick={deleteFile}>
            <Trash />
          </button>
        )}
      </>
    );
  };

  const getFileMessageWrapper = () => {
    return data.fileLink ? (
      <Link className={styles.msgWrapper} target="_blank" download to={data.fileLink}>
        {fileMessageContent()}
      </Link>
    ) : (
      <div className={styles.msgWrapper} onClick={onClick}>
        {fileMessageContent()}
      </div>
    );
  };

  useEffect(() => {
    if (data.file) {
      const uploadLocalFile = async () => {
        setFileUploading(true);
        const formData = new FormData();
        formData.append('files', data.file, data.file.name);
        formData.append('session_id', AuthHelpers.getWSSessionId());
        await uploadFile(formData);
        setFileUploading(false);
      };

      uploadLocalFile().catch(console.error);
    }
  }, []);

  return <>{getFileMessageWrapper()}</>;
};

export default FileMessage;
