import React, { FC } from 'react';
import { ButtonInterface } from 'interfaces';
import classes from './ButtonOutlined.module.scss';

const ButtonOutlined: FC<ButtonInterface> = ({ text, onClick, type = 'button' }) => {
  return (
    <button type={type} className={classes.button} onClick={onClick}>
      {text}
    </button>
  );
};

export default ButtonOutlined;
