import React, { FC } from 'react';
import styles from './highlightBlock.module.scss';

type Props = {
  title: string;
  text: string;
};

const HighlightBlock: FC<Props> = ({ title, text }) => {
  return (
    <div className={styles.wrapper}>
      <p className={styles.title}>{title}</p>
      <div className={styles.textWrapper}>
        <p className={styles.text}>{text}</p>
      </div>
    </div>
  );
};

export default HighlightBlock;
