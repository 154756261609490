import { api } from './axios.config';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface ILoginRes {
  access_token: string;
  token_type: string;
}

export interface IGoogleAuthorizeRes {
  authorization_url: string;
}

interface IGoogleCallbackParams {
  code: string;
  code_verifier?: string;
  state: string;
  error?: string;
}

export const signInApi = ({ username, password }): Promise<ILoginRes> => {
  const form = new FormData();
  form.append('username', username);
  form.append('password', password);
  return api.post('/auth/jwt/login', form, {
    headers: {
      'Content-Type': 'x-www-form-urlencoded',
    },
  });
};

export const signUpApi = ({ username, email, password, gender, dob }): Promise<ILoginRes> => {
  return api.post(
    '/auth/register',
    {
      username,
      email,
      password,
      gender,
      dob,
      isActive: true,
      is_superuser: false,
      is_verified: false,
      avatar_link:
        'https://cdn.shopify.com/s/files/1/2715/7980/articles/Pomeranian_Running.jpg?v=1682032382',
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

export const loginApi = (password): Promise<ILoginRes> => {
  return api.post('/demo/verify', { password });
};

export const logoutApi = () => {
  return api.post('/auth/jwt/logout');
};

export const googleAuthorizeApi = (): Promise<IGoogleAuthorizeRes> => {
  return api.get('/auth/google/authorize');
};

export const googleCallbackApi = (params: IGoogleCallbackParams): Promise<ILoginRes> => {
  return api.get('/auth/google/callback', {
    params,
  });
};
