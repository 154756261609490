import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { GeneralEffects, GeneralSelectors } from 'store';
import Input from '../../form-controls/input/Input';
import Button from '../../button/Button';
import style from 'styles/modules/Auth.module.scss';

interface Form {
  username: string;
  password: string;
}

export const SignInForm: FC = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Form>();

  const isError = useSelector(GeneralSelectors.selectIsError);
  const isLoggedIn = useSelector(GeneralSelectors.selectIsLoggedIn);

  const navigate = useNavigate();

  const dispatch: ThunkDispatch<unknown, unknown, AnyAction> = useDispatch();

  const onLogin: SubmitHandler<Form> = ({ username, password }) => {
    dispatch(GeneralEffects.signIn({ username, password }));
  };

  useEffect(() => {
    if (isLoggedIn) {
      reset();
      navigate('/');
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (isError) {
      console.error('Got error while trying to login');
    }
  }, [isError]);

  return (
    <form className={`form ${style.form}`} onSubmit={handleSubmit(onLogin)}>
      <div className={style.inputBlock}>
        <Input
          inputLabel="Username"
          labelClassList={['labelWhite']}
          inputClassList={['inputTransparent']}
          label="username"
          register={register}
          type="text"
          placeholder="Username"
          errors={errors}
        />
      </div>
      <div className={style.inputBlock}>
        <Input
          inputLabel="Password"
          labelClassList={['labelWhite']}
          inputClassList={['inputTransparent']}
          label="password"
          register={register}
          type="password"
          placeholder="Password"
          errors={errors}
        />
      </div>
      <div className={style.buttonWrapper}>
        <Button type={'submit'} text={'Sign In'} onClick={() => handleSubmit(onLogin)} />
      </div>
      <input type={'submit'} hidden />
    </form>
  );
};

export default SignInForm;
