import React, { FC } from 'react';
import styles from 'pages/product/ProductPage.module.scss';
import phone from 'assets/images/app-at-phone.png';
import { ReactComponent as ProfileIcon } from 'assets/icons/feature-icon-profile.svg';
import { ReactComponent as ClassificationIcon } from 'assets/icons/feature-icon-classification.svg';
import { ReactComponent as SupportIcon } from 'assets/icons/feature-icon-support.svg';

export const features = [
  {
    id: 1,
    title: 'Ability to assimilate patient profile information',
    description:
      "Such as age, biological sex, and medical history. Based on this data, BetterMed incorporates an intelligent triage system that suggests the most appropriate healthcare professional or specialist for each individual case. Not only does this enhance the quality of care, but it also improves efficiency by providing a concise summary of the patient's health issue to the treating phy sician.",
    icon: ProfileIcon,
  },
  {
    id: 2,
    title: 'Intelligent classification system',
    description:
      "That assesses the urgency of a patient's case and prioritizes them in the queue accordingly. This feature ensures effective management of critical cases by either instantly connecting the patient to a healthcare professional or by efficiently managing the queue.",
    icon: ClassificationIcon,
  },
  {
    id: 3,
    title: 'Significant support',
    description:
      'When immediate access to a doctor may not be possible. Its robust capabilities make it a compelling addition to any healthcare establishment, ensuring the delivery of high-quality, patient-centered care.',
    icon: SupportIcon,
  },
];

const ProductPage: FC = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.titleWrapper}>
        <h2 className={styles.title}>Why BetterMed?</h2>
      </div>
      <div className={styles.description}>
        <p className={styles.descriptionText}>
          BetterMed is an innovative virtual medical assistant, designed with the goal of enhancing
          the effectiveness of healthcare providers in the rapidly advancing medical landscape.
          Powered by a unique Large Language Model (LLM), BetterMed employs an advanced blend of
          knowledge sourced from a wide array of medical resources. Its performance surpasses
          standard language models in terms of medical accuracy and relevancy, making it an ideal
          tool for a myriad of healthcare applications.
        </p>
        <p className={styles.descriptionText}>
          BetterMed works seamlessly, offering an interactive and patient-centric experience. It is
          proficient in asking pertinent follow-up questions and can provide comprehensive
          instructions based on patient feedback, thereby offering a unique blend of empathy and
          efficiency that is essential in the medical field.
        </p>
      </div>
      <div className={styles.features}>
        <h3 className={styles.featuresTitle}>Application Features</h3>
        <div className={styles.featuresWrapper}>
          <div className={styles.featuresList}>
            {features.map((feature) => (
              <div className={styles.feature} key={feature.id}>
                <div>
                  <feature.icon className={styles.featureIcon} />
                </div>
                <div className={styles.featureContent}>
                  <h4 className={styles.featureTitle}>{feature.title}</h4>
                  <p className={styles.featureDescription}>{feature.description}</p>
                </div>
              </div>
            ))}
          </div>
          <div className={styles.phone}>
            <img src={phone} alt="working app illustration" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ProductPage);
