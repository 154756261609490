import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { LoginBody, SignInBody, SignUpBody } from 'interfaces';
import { loginApi, logoutApi, signInApi, signUpApi } from 'api/authApi';
import AuthHelpers from 'utils/AuthHelpers';

interface GeneralState {
  isDemoRequestAccepted: boolean;
  isLoggedIn: boolean;
  isRegistered: boolean;
  isLoading: boolean;
  isError: boolean;
}

const initialState: GeneralState = {
  isDemoRequestAccepted: process.env.NODE_ENV === 'development',
  isLoggedIn: false,
  isRegistered: false,
  isLoading: false,
  isError: false,
};

const signIn = createAsyncThunk('general/signIn', async ({ username, password }: SignInBody) => {
  const res = await signInApi({ username, password });

  AuthHelpers.storeAccessToken(res.access_token);
});

const signUp = createAsyncThunk(
  'general/signUp',
  async ({ username, password, email, gender, dob }: SignUpBody) => {
    const res = await signUpApi({ username, password, email, gender, dob });

    AuthHelpers.storeAccessToken(res.access_token);
  }
);

const requestDemo = createAsyncThunk('general/requestDemo', async ({ password }: LoginBody) => {
  await loginApi(password);
});

const logout = createAsyncThunk('general/logout', async () => {
  try {
    await logoutApi();
    AuthHelpers.clearStorage();
  } catch (e) {
    AuthHelpers.clearStorage();
  }
});

const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    setIsLoggedIn(state) {
      state.isLoggedIn = true;
    },
    logout(state) {
      state.isLoggedIn = false;
    },
    resetState(state) {
      state.isLoggedIn = false;
      state.isDemoRequestAccepted = false;
      state.isError = false;
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(requestDemo.pending, (state) => {
      state.isDemoRequestAccepted = false;
      state.isError = false;
      state.isLoading = true;
    });
    builder.addCase(requestDemo.rejected, (state) => {
      state.isDemoRequestAccepted = false;
      state.isLoading = false;
      state.isError = true;
    });
    builder.addCase(requestDemo.fulfilled, (state) => {
      state.isDemoRequestAccepted = true;
      state.isLoading = false;
      state.isError = false;
    });
    builder.addCase(signIn.pending, (state) => {
      state.isLoggedIn = false;
      state.isLoading = true;
      state.isError = false;
    });
    builder.addCase(signIn.rejected, (state) => {
      state.isLoggedIn = false;
      state.isLoading = false;
      state.isError = true;
    });
    builder.addCase(signIn.fulfilled, (state) => {
      state.isLoggedIn = true;
      state.isLoading = false;
      state.isError = false;
    });
    builder.addCase(logout.pending, (state) => {
      state.isLoggedIn = true;
      state.isLoading = true;
      state.isError = false;
    });
    builder.addCase(logout.rejected, (state) => {
      state.isLoggedIn = true;
      state.isLoading = false;
      state.isError = true;
    });
    builder.addCase(logout.fulfilled, (state) => {
      state.isLoggedIn = false;
      state.isLoading = false;
      state.isError = false;
    });
    builder.addCase(signUp.pending, (state) => {
      state.isRegistered = false;
      state.isLoading = true;
      state.isError = false;
    });
    builder.addCase(signUp.rejected, (state) => {
      state.isRegistered = false;
      state.isLoading = false;
      state.isError = true;
    });
    builder.addCase(signUp.fulfilled, (state) => {
      state.isRegistered = true;
      state.isLoading = false;
      state.isError = false;
    });
  },
});

export const GeneralActions = { ...generalSlice.actions };
export const GeneralEffects = {
  requestDemo,
  signIn,
  logout,
  signUp,
};
export const generalReducer = generalSlice.reducer;
