import { useDispatch } from 'react-redux';

import * as GeneralSelectors from './selectors/general';
import * as UserInfoSelectors from './selectors/user';
import { GeneralActions, GeneralEffects } from './slices/general';
import { UserInfoActions } from './slices/user';
import store from './store';

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;

export {
  GeneralActions,
  GeneralEffects,
  GeneralSelectors,
  store,
  UserInfoSelectors,
  UserInfoActions,
};
