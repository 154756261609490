import React, { FC } from 'react';
import { useGlobalModalContext } from 'contexts/GlobalModalContext';
import ModalWindow from 'components/shared/modal-window/ModalWindow';
import classes from 'styles/modules/Modal.module.scss';

interface Props {
  imageSrc: string;
}

export const PreviewImageModal: FC<Props> = ({ imageSrc }) => {
  const { hideModal } = useGlobalModalContext();

  return (
    <ModalWindow visible={true} setVisible={hideModal}>
      <img className={classes.imagePreview} src={imageSrc} alt="image" />
    </ModalWindow>
  );
};

export default PreviewImageModal;
