import React, { FC } from 'react';
import { useGlobalModalContext } from 'contexts/GlobalModalContext';
import AdditionalInformationForm from '../shared/forms/additional-information-form/AdditionalInformationForm';
import ModalWindow from '../shared/modal-window/ModalWindow';
import classes from 'styles/modules/Modal.module.scss';

export const DemoRequestModal: FC = () => {
  const { hideModal } = useGlobalModalContext();

  return (
    <ModalWindow
      visible={true}
      setVisible={hideModal}
      classes={`${classes.modalOverflow} ${classes.modal}`}
    >
      <div className={classes.modalWrapper}>
        <h2 className={classes.modalHeading}>Additional Information</h2>
        <AdditionalInformationForm />
      </div>
    </ModalWindow>
  );
};

export default DemoRequestModal;
