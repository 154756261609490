import React, { FC, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { GeneralEffects, GeneralSelectors } from 'store';
import { MODAL_TYPES, useGlobalModalContext } from 'contexts/GlobalModalContext';
import Input from '../../form-controls/input/Input';
import Button from '../../button/Button';
import style from 'styles/modules/Auth.module.scss';

interface Form {
  password: string;
}

export const DemoRequestForm: FC = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Form>();
  const { showModal, hideModal } = useGlobalModalContext();

  const isError = useSelector(GeneralSelectors.selectIsError);
  const isDemoRequestAccepted = useSelector(GeneralSelectors.selectIsDemoRequestAccepted);

  const dispatch: ThunkDispatch<unknown, unknown, AnyAction> = useDispatch();

  const onLogin: SubmitHandler<Form> = ({ password }) => {
    dispatch(GeneralEffects.requestDemo({ password }));
  };

  useEffect(() => {
    if (isDemoRequestAccepted) {
      reset();
      hideModal();
      showModal(MODAL_TYPES.ADDITIONAL_INFORMATION_MODAL);
    }
  }, [isDemoRequestAccepted]);

  useEffect(() => {
    if (isError) {
      console.error('Got error while trying to login');
    }
  }, [isError]);

  return (
    <form className={`form ${style.form}`} onSubmit={handleSubmit(onLogin)}>
      <div className={style.inputBlock}>
        <Input
          label="password"
          register={register}
          type="password"
          placeholder="Password"
          errors={errors}
        />
      </div>
      <div className={style.buttonWrapper}>
        <Button type={'submit'} text={'Continue'} onClick={() => handleSubmit(onLogin)} />
      </div>
      <input type={'submit'} hidden />
    </form>
  );
};

export default DemoRequestForm;
