import React, { FC, useState, createContext, useContext, ReactNode } from 'react';
import DemoRequestModal from 'components/modals/DemoRequestModal';
import AdditionalInformationModal from 'components/modals/AdditionalInformationModal';
import CloseChatConfirmationModal from 'components/modals/CloseChatConfirmationModal';
import ReportLoadingModal from 'components/modals/ReportLoadingModal';
import ReportModal from 'components/modals/ReportModal';
import DeleteAttachmentConfirmationModal from 'components/modals/DeleteAttachmentConfirmationModal';
import PreviewImageModal from 'components/modals/PreviewImageModal';

export const MODAL_TYPES = {
  DEMO_REQUEST_MODAL: 'DEMO_REQUEST_MODAL',
  ADDITIONAL_INFORMATION_MODAL: 'ADDITIONAL_INFO_MODAL',
  CLOSE_CHAT_CONFIRMATION_MODAL: 'CLOSE_CHAT_CONFIRMATION_MODAL',
  REPORT_LOADING_MODAL: 'REPORT_LOADING_MODAL',
  REPORT_MODAL: 'REPORT_MODAL',
  DELETE_ATTACHMENT_CONFIRMATION_MODAL: 'DELETE_ATTACHMENT_CONFIRMATION_MODAL',
  PREVIEW_IMAGE_MODAL: 'PREVIEW_IMAGE_MODAL',
};

const MODAL_COMPONENTS: any = {
  [MODAL_TYPES.DEMO_REQUEST_MODAL]: DemoRequestModal,
  [MODAL_TYPES.ADDITIONAL_INFORMATION_MODAL]: AdditionalInformationModal,
  [MODAL_TYPES.CLOSE_CHAT_CONFIRMATION_MODAL]: CloseChatConfirmationModal,
  [MODAL_TYPES.REPORT_LOADING_MODAL]: ReportLoadingModal,
  [MODAL_TYPES.REPORT_MODAL]: ReportModal,
  [MODAL_TYPES.DELETE_ATTACHMENT_CONFIRMATION_MODAL]: DeleteAttachmentConfirmationModal,
  [MODAL_TYPES.PREVIEW_IMAGE_MODAL]: PreviewImageModal,
};

type GlobalModalContext = {
  showModal: (modalType: string, modalProps?: object) => void;
  hideModal: () => void;
  store: any;
};

/* eslint-disable */
const initialState: GlobalModalContext = {
  showModal: () => {},
  hideModal: () => {},
  store: {},
};

const GlobalModalContext = createContext(initialState);
export const useGlobalModalContext = () => useContext(GlobalModalContext);

export const GlobalModal: FC<{ children: ReactNode }> = ({ children }) => {
  const [store, setStore] = useState({});
  // @ts-ignore
  const { modalType, modalProps } = store || {};

  const showModal = (modalType: string, modalProps: any = {}) => {
    setStore({
      ...store,
      modalType,
      modalProps,
    });
  };

  const hideModal = () => {
    setStore({
      ...store,
      modalType: null,
      modalProps: {},
    });
  };

  const renderComponent = () => {
    const ModalComponent = MODAL_COMPONENTS[modalType];
    if (!modalType || !ModalComponent) {
      return null;
    }
    return <ModalComponent id="global-modal" {...modalProps} />;
  };

  return (
    <GlobalModalContext.Provider value={{ store, showModal, hideModal }}>
      {renderComponent()}
      {children}
    </GlobalModalContext.Provider>
  );
};
/* eslint-enable */
