import { RouteObject } from 'react-router';
import {
  ROUTE_ABOUT_US,
  ROUTE_CONTACT_US,
  ROUTE_PRODUCT,
  ROUTE_ROOT,
  ROUTE_SIGN_IN,
  ROUTE_SIGN_UP,
  ROUTE_USER_ACCOUNT,
} from 'app-constants';
import {
  LandingHome,
  UserAccountHome,
  ContactUs,
  UserAccountHomePage,
  SignIn,
  LandingHomePage,
} from 'modules';
import { ProductPage, AboutUsPage } from 'pages';
import SignUp from './modules/landing/pages/sign-up/SignUp';

export const ROUTES: RouteObject[] = [
  {
    path: ROUTE_ROOT,
    element: <LandingHome />,
    children: [
      {
        path: '',
        index: true,
        element: <LandingHomePage />,
      },
      {
        path: ROUTE_CONTACT_US,
        element: <ContactUs />,
      },
      {
        path: ROUTE_SIGN_IN,
        element: <SignIn />,
      },
      {
        path: ROUTE_SIGN_UP,
        element: <SignUp />,
      },
    ],
  },
  {
    path: ROUTE_USER_ACCOUNT,
    element: <UserAccountHome />,
    children: [
      {
        path: '',
        index: true,
        element: <UserAccountHomePage />,
      },
    ],
  },
  {
    path: ROUTE_ABOUT_US,
    element: <AboutUsPage />,
  },
  {
    path: ROUTE_PRODUCT,
    element: <ProductPage />,
  },
];
