import React from 'react';
import './App.scss';
import { useRoutes } from 'react-router-dom';
import { ROUTES } from 'routes';
import { Header } from 'components/common/Header/Header';

function AppRoutes() {
  return useRoutes(ROUTES);
}

function App() {
  return (
    <div className="App">
      <Header />
      <AppRoutes />
    </div>
  );
}

export default App;
