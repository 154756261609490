import React, { FC, useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { GeneralEffects, GeneralSelectors } from 'store';
import Input from '../../form-controls/input/Input';
import Button from '../../button/Button';
import style from 'styles/modules/Auth.module.scss';
import InputSelect from 'components/shared/form-controls/inputSelect/inputSelect';
import { useNavigate } from 'react-router-dom';

interface Form {
  username: string;
  email: string;
  password: string;
  gender: string;
  dob: Date;
}

const options = [
  { value: 'Male', label: 'Male' },
  { value: 'Female', label: 'Female' },
  { value: 'Other', label: 'Other' },
];

export const SignUpForm: FC = () => {
  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm<Form>();

  const isRegistered = useSelector(GeneralSelectors.selectIsRegistered);
  const isError = useSelector(GeneralSelectors.selectIsError);

  const navigate = useNavigate();

  const dispatch: ThunkDispatch<unknown, unknown, AnyAction> = useDispatch();

  const onLogin: SubmitHandler<Form> = ({ username, password, email, dob, gender }) => {
    dispatch(GeneralEffects.signUp({ username, password, email, dob: new Date(dob), gender }));
  };

  useEffect(() => {
    if (isRegistered) {
      reset();
      navigate('/sign-in');
    }
  }, [isRegistered]);

  useEffect(() => {
    if (isError) {
      console.error('Got error while trying to sign up');
    }
  }, [isError]);

  return (
    <form className={`form ${style.form}`} onSubmit={handleSubmit(onLogin)}>
      <div className={style.inputBlock}>
        <Input
          inputLabel="Username"
          labelClassList={['labelWhite']}
          inputClassList={['inputTransparent']}
          label="username"
          register={register}
          type="text"
          placeholder="Username"
          errors={errors}
        />
      </div>
      <div className={style.inputBlock}>
        <Input
          inputLabel="Email"
          labelClassList={['labelWhite']}
          inputClassList={['inputTransparent']}
          label="email"
          register={register}
          type="text"
          placeholder="Email"
          errors={errors}
        />
      </div>
      <div className={style.inputBlock}>
        <Input
          inputLabel="Password"
          labelClassList={['labelWhite']}
          inputClassList={['inputTransparent']}
          label="password"
          register={register}
          type="password"
          placeholder="Password"
          errors={errors}
        />
      </div>
      <div className={style.inputBlock}>
        <Controller
          control={control}
          name={'gender'}
          rules={{ required: true }}
          render={({ field: { value, name, ref } }) => (
            <InputSelect
              ref={ref}
              name={name}
              options={options}
              currentValue={value}
              setValue={setValue}
              placeholder={'Select your biological sex'}
              label={'Biological sex'}
              labelClassList={['labelWhite']}
              inputClassList={['inputTransparent']}
            />
          )}
        />
      </div>
      <div className={style.inputBlock}>
        <Input
          inputLabel="Date of Birth"
          labelClassList={['labelWhite']}
          inputClassList={['inputTransparent']}
          label="dob"
          register={register}
          type="date"
          placeholder="Date of Birth"
          errors={errors}
        />
      </div>
      <div className={style.buttonWrapper}>
        <Button type={'submit'} text={'Sign Up'} onClick={() => handleSubmit(onLogin)} />
      </div>
      <input type={'submit'} hidden />
    </form>
  );
};

export default SignUpForm;
