import axios from 'axios';
import AuthHelpers from 'utils/AuthHelpers';

export const baseURL = process.env.REACT_APP_API_BASEURL;

export const api = axios.create({
  baseURL: baseURL,
});

api.interceptors.request.use(
  function (config: any) {
    const TOKEN = AuthHelpers.getAccessToken();
    if (TOKEN) {
      config.headers.Authorization = `Bearer ${TOKEN}`;
    }
    console.log({ config });
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
api.interceptors.response.use(
  function (response) {
    console.log({ response });
    return response?.data;
  },
  function (error) {
    if (error.status === 401) {
      AuthHelpers.clearStorage();
    }

    return Promise.reject(error.response);
  }
);

export default api;
