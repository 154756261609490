import chat from 'assets/icons/chat.svg';
import document from 'assets/icons/document.svg';
import sort from 'assets/icons/sort.svg';

export const FEATURES = [
  {
    id: 0,
    icon: chat,
    name: 'Interactive Chat',
    caption: 'In-depth health consultations',
  },
  {
    id: 1,
    icon: sort,
    name: 'Intelligent Triage System',
    caption: 'Guided specialist referrals',
  },
  {
    id: 2,
    icon: document,
    name: 'Clinical Case Synopsis',
    caption: 'Concise medical summaries',
  },
];
